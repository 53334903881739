.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;

}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}  

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1rem 0 0.1rem;
    text-align: center;
    color: var(--color-primary);
}
.portfolio__item h4 {
    margin: 0.7rem 0 2rem;
    text-align: center;
}

.portfolio__item-cta{
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    
}

.portfolio__item-cta a{
    display: block;
    margin:auto;
    
}


/* ========================= MEDIA QUERIES (MEDIUM DEVICE) ===============*/

@media screen and (max-width : 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ========================= MEDIA QUERIES (SMALL DEVICE) ===============*/

@media screen and (max-width : 600px) {
    .portfolio__container{
        grid-template-columns: 1fr ;
        gap: 1rem;
    }
}